<template>
  <MyDialog ref="dialog" :isShow.sync="isShow" :width="large?'779px':'280px'"
            :height="large?'500px':'480px'" :isBg="true" :isTitle="false" zIndex="3000"
            :maskClose="false" bg="rgba(0, 0, 0, 0.5)">
    <div class="content" v-if="!large">
      <img class="poster_img hand" @click="toActivity"
           src="https://img.infox-med.com/webImg/infoPc/activity/invite12Dialog.png" alt="">
      <i class="line"></i>

      <i class="el-icon-circle-close close hand" @click="handleClose"></i>
    </div>

    <div class="large_content" v-else>
      <img class="large_img"
           src="https://img.infox-med.com/webImg/appH5/invite/13/bigWheelBanner2.png" alt="">

      <i class="el-icon-circle-close large_close hand" @click="handleClose"></i>
    </div>
    <!-- @handleClose="handleClose" -->
  </MyDialog>
</template>

<script>
import MyDialog from '@/components/myDialog/newIndex.vue'
import { showPoster } from '@/utils/index.js'
export default {
  props: {
    large: {
      type: Boolean,
      default: true
    }
  },
  components: {
    MyDialog
  },
  data () {
    return {
      isShow: false
    }
  },
  methods: {
    toActivity () {
      this.$refs.dialog.closeDialog()
      // if (!this.$store.getters.userId) return this.$login()
      sessionStorage.setItem('poster', '1')
      this.$router.push('/activityContent')
    },
    handleClose () {
      sessionStorage.setItem('poster', '1')
      this.$refs.dialog.closeDialog()
    }
  },
  created () {
    // eslint-disable-next-line no-undef
    this.isShow = !sessionStorage.getItem('poster') && showPoster
    // sessionStorage.setItem('poster', '1')
  }
}
</script>

<style lang="less" scoped>
/deep/.dialog_boby {
  background-color: transparent;
}
.content {
  width: 100%;
  height: 100%;
  padding-bottom: 50px;
  position: relative;

  .line {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 1px;
    z-index: 80;
    height: 70%;
    background-color: #fff;
  }

  .close {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    font-size: 30px;
    color: #fff;
  }
}

.poster_img {
  width: 100%;
  position: relative;
  z-index: 99;
}
.large_content {
  position: relative;
  display: grid;
  align-items: center;
  justify-items: center;
  .large_close {
    position: absolute;
    bottom: -80px;
    left: 50%;
    transform: translate(-50%, 0);
    font-size: 40px;
    transition: all 0.5s;
    color: #fff;
    &:hover {
      transform: translate(-50%, 0) rotate(180deg);
    }
  }
}
.large_img {
  height: 420px;
}
</style>
